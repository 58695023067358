import {
    Button,
    Col,
    Container,
    Dropdown,
    Form,
    Modal,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
} from "react-bootstrap";
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import {
    dateFormate,
    formatWithDecimals,
    transactionurl,
} from "../../config/config";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { apiService } from "../../service/api.service";
import Select from "react-select";
import { useLocation } from "react-router-dom";


export const FailedTransactions = () => {
    const [page, setPage] = useState(1);
    const [transactions, settransactions] = useState([]);
    const [transactionIds, setTransactionIds] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loader, setLoader] = useState(false);
    const [filterValue, setFilterValue] = useState('FAILED');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const showGasfee = searchParams.get('showGasfee'); // get the 'showGasfee' parameter

    const filterOptions = [
        // { value: "", label: "All" },
        { value: "EXECUTABLE", label: "Executable" },
        { value: "FAILED", label: "Failed" },
        { value: "PENDING", label: "Pending" },
    ];

    const statusDropdown = [
        {
            name: "FAILED",
            id: "FAILED",
        },
        {
            name: "PENDING",
            id: "PENDING",
        },
    ];


    useEffect(() => {
        transaction_list(page, filterValue);
    }, []);


    async function transaction_list(page, filterType) {
        setLoader(true);
        try {
            if (filterType && filterType === "EXECUTABLE") {
                let params = {
                    page: page
                }
                const response = await apiService.get_executable_transations_list(params);
                if (response?.status == 200) {
                    let totalItems = response.data?.pagination?.totalItems ? response.data.pagination.totalItems : 0
                    let list = response.data?.data ? response.data.data : null
                    settransactions(list);
                    setTotalItems(totalItems);
                    setLoader(false);
                }
            }
            else {
                let params = {
                    page: page,
                    status: filterType ? filterType : ''
                }
                const response = await apiService.get_failed_transations_list(params);
                if (response?.status == 200) {
                    let totalItems = response.data?.pagination?.totalItems ? response.data.pagination.totalItems : 0
                    let list = response.data?.data ? response.data.data : null
                    settransactions(list);
                    setTotalItems(totalItems);
                    setLoader(false);
                }
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
        }
    };


    function handlePageChange(pageNumber) {
        if (page != pageNumber) {
            setPage(pageNumber);
            transaction_list(pageNumber, filterValue);
            setTransactionIds([]);
        }
    };


    function handleStatusConfimation(transactionId, status) {
        let actionType = status == "FAILED" ? "PENDING" : "FAILED"
        swal({
            title: 'Are you sure?',
            text: `Are you sure you want to change status ${status} to ${actionType}?`,
            icon: 'warning',
            buttons: ['Cancel', `Yes, ${actionType}`],
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                handleTransactionStaus(transactionId, actionType);
            }
        })
    };


    async function handleTransactionStaus(transactionIds, status) {
        try {
            const response = await apiService.transaction_status_change(transactionIds, status);
            if (response.status === 200) {
                setLoader(true);
                if (transactions?.length == 1) {
                    setPage(1)
                    transaction_list(1, filterValue);
                }
                else {
                    transaction_list(page, filterValue);
                }
            }
        }
        catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
        }
    };


    function handleFilterChange(value) {
        setPage(1);
        transaction_list(1, value);
        setTransactionIds([]);
    };

    const [showSoloModal, setShowSoloModal] = useState(false);
    const [soloPredictionType, setSoloPredictionType] = useState('pearls');

    async function getSoloPredictionCount(type) {
        try {
            setLoader(true);
            const response = await apiService.soloPredictionCount(type);
            if (response.status == 200) {
                setShowSoloModal(false);
                swal({
                    text: `Solo ${type} predictions are ${response.data.count}.
                    Would you like to add?`,
                    icon: "info",
                    buttons: ["No", "Yes"]
                }).then(async (res) => {
                    if (res) {
                        try {
                            const response1 = await apiService.soloPredictionCron(type);
                            if (response1.status == 200) {
                                swal({
                                    text: `${response1.data.message}`,
                                    icon: "success",
                                });
                            }
                            setLoader(false);

                        } catch (error) {
                            if (error?.response?.status == 401) {
                                setLoader(false);
                                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                                    localStorage.clear();
                                    window.location.href = "/";
                                    console.log("OK button clicked after error alert");
                                });
                            } else {
                                setLoader(false);
                                swal({
                                    icon: "error",
                                    text: error?.response?.data?.message
                                        ? error?.response?.data?.message
                                        : error?.message,
                                    button: "OK",
                                }).then(() => {
                                    console.log("OK button clicked after error alert");
                                });
                            }
                            setShowSoloModal(false);
                        }
                    } else {
                        setLoader(false);
                    }
                })
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
            setShowSoloModal(false);
        }
    }





    const [showFolioGroupModal, setShowFolioGroupModal] = useState(false);
    const [folioGroupPredictionType, setFolioGroupPredictionType] = useState('Monday');

    async function getFolioGroupPredictionCount(type) {
        try {
            setLoader(true);
            const response = await apiService.folioGroupPredictionCount(type);
            if (response.status == 200) {
                setShowFolioGroupModal(false);
                swal({
                    text: `Folio group ${type} predictions are ${response.data.count}.
                    Would you like to add?`,
                    icon: "info",
                    buttons: ["No", "Yes"]
                }).then(async (res) => {
                    if (res) {
                        try {
                            const response1 = await apiService.folioGroupPredictionCron(type);
                            if (response1.status == 200) {
                                swal({
                                    text: `${response1.data.message}`,
                                    icon: "success",
                                });
                            }
                            setLoader(false);

                        } catch (error) {
                            if (error?.response?.status == 401) {
                                setLoader(false);
                                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                                    localStorage.clear();
                                    window.location.href = "/";
                                    console.log("OK button clicked after error alert");
                                });
                            } else {
                                setLoader(false);
                                swal({
                                    icon: "error",
                                    text: error?.response?.data?.message
                                        ? error?.response?.data?.message
                                        : error?.message,
                                    button: "OK",
                                }).then(() => {
                                    console.log("OK button clicked after error alert");
                                });
                            }
                            setShowFolioGroupModal(false);
                        }
                    } else {
                        setLoader(false);
                    }
                })
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
            setShowFolioGroupModal(false);
        }
    }


    const [showDailyGroupModal, setShowDailyGroupModal] = useState(false);
    const [dailyGroupPredictionType, setDailyGroupPredictionType] = useState('Monday');

    async function getDailyGroupPredictionCount(type) {
        try {
            setLoader(true);
            const response = await apiService.dailyGroupPredictionCount(type);
            if (response.status == 200) {
                setShowDailyGroupModal(false);
                swal({
                    text: `Daily group ${type} predictions are ${response.data.count}.
                    Would you like to add?`,
                    icon: "info",
                    buttons: ["No", "Yes"]
                }).then(async (res) => {
                    if (res) {
                        try {
                            const response1 = await apiService.dailyGroupPredictionCron(type);
                            if (response1.status == 200) {
                                swal({
                                    text: `${response1.data.message}`,
                                    icon: "success",
                                });
                            }
                            setLoader(false);

                        } catch (error) {
                            if (error?.response?.status == 401) {
                                setLoader(false);
                                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                                    localStorage.clear();
                                    window.location.href = "/";
                                    console.log("OK button clicked after error alert");
                                });
                            } else {
                                setLoader(false);
                                swal({
                                    icon: "error",
                                    text: error?.response?.data?.message
                                        ? error?.response?.data?.message
                                        : error?.message,
                                    button: "OK",
                                }).then(() => {
                                    console.log("OK button clicked after error alert");
                                });
                            }
                            setShowDailyGroupModal(false);
                        }
                    } else {
                        setLoader(false);
                    }
                })
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false);
                swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear();
                    window.location.href = "/";
                    console.log("OK button clicked after error alert");
                });
            } else {
                setLoader(false);
                swal({
                    icon: "error",
                    text: error?.response?.data?.message
                        ? error?.response?.data?.message
                        : error?.message,
                    button: "OK",
                }).then(() => {
                    console.log("OK button clicked after error alert");
                });
            }
            setShowDailyGroupModal(false);
        }
    }


    function selectAllTransaction(event) {
        let transactionIdsTemp = [];
        if (event.target.checked) {
            transactionIdsTemp = transactions.map(item => item._id);
        }
        setTransactionIds(transactionIdsTemp);
    }

    const isAllSelected = useMemo(() => {
        return transactions.every(item => transactionIds.includes(item._id));
    }, [transactionIds, transactions]);


    function selectAndRemove(transactionId) {
        setTransactionIds((prevTransactionIds) => {
            // Check if the transactionId is already in the list
            if (prevTransactionIds.includes(transactionId)) {
                // If it exists, remove it
                return prevTransactionIds.filter(id => id !== transactionId);
            } else {
                // If it does not exist, add it
                return [...prevTransactionIds, transactionId];
            }
        });
    }



    return (
        <div className="dashboard-main-area">
            {loader == true ? <ThreeDotSpinner /> : ""}
            <Container fluid>
                <div className="dashboard-area-heading management-heading">
                    <Row className="row justify-content-center align-items-center mb-3">
                        <Col md={12} lg={11}>
                            <div className="heading-top-area">
                                <div className="d-flex ">
                                    <h2 className="align-self-center m-0">Failed Transaction</h2>
                                </div>
                                <div className="heading-top-area-right">
                                    {transactionIds.length >= 1 ? <Button type="buttton" variant="unset" onClick={() => handleStatusConfimation(transactionIds, "PENDING")}>Move To Failed Transactions</Button> : ""}
                                    <Button type="buttton" variant="unset" onClick={() => setShowDailyGroupModal(true)}>Launch Daily Group Prediction</Button>
                                    <Button type="buttton" variant="unset" onClick={() => setShowFolioGroupModal(true)}>Launch Folio Group Prediction</Button>
                                    <Button type="buttton" variant="unset" onClick={() => setShowSoloModal(true)}>Launch Solo Prediction</Button>
                                    <div className="filter-top-area me-2">
                                        <Form.Group
                                            className=""
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label className="mb-0">Filter:</Form.Label>
                                            <Select
                                                options={filterOptions}
                                                defaultValue={filterOptions[1]}
                                                onChange={(e) => (handleFilterChange(e.value), setFilterValue(e.value))}
                                            />
                                        </Form.Group>
                                    </div>

                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row className="pb-4 justify-content-center">
                        <Col md={12} lg={11}>
                            <div className="audit-request-box">
                                <Table responsive="sm" className="UserListTable">
                                    <thead>
                                        <tr>
                                            {transactions?.length > 0 && filterValue == "PENDING" ? <th>All <input type="checkbox" checked={isAllSelected} onChange={selectAllTransaction} /></th> : ""}
                                            <th>Sr. No.</th>
                                            <th>Prediction Name</th>
                                            <th>Prediction Type</th>
                                            <th>Data Provider</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                            {filterValue != "PENDING" && showGasfee ? <>
                                                <th>Launched Gas</th>
                                                <th>Failed Reason</th>
                                            </>:""}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {totalItems != null &&
                                            totalItems > 0 &&
                                            transactions &&
                                            transactions?.length > 0 ? (
                                            transactions.map((data, index) => {

                                                const serialNumber = (page - 1) * 10 + index + 1;
                                                let predictionDetail = data.params && data.params.length > 0 ? data.params[0] : ''

                                                let predictionQuestion = predictionDetail?.question ? predictionDetail.question : ''
                                                let predictionName = predictionQuestion && predictionQuestion.length > 50 ? predictionQuestion.slice(0, 50) + "..." : predictionQuestion

                                                let predictionType = predictionDetail?.prediction_type ? predictionDetail?.prediction_type === "RISK-FREE" ? "Prize pool" : predictionDetail?.prediction_type : "N/A"

                                                return (
                                                    <tr key={index}>
                                                        {filterValue == "PENDING" ? <td><input type="checkbox" checked={transactionIds.includes(data._id)} onChange={(event) => selectAndRemove(data._id)} /></td> : ""}
                                                        <td>{serialNumber}</td>
                                                        <td>
                                                            {/* {predictionName ? predictionName : "N/A"} */}

                                                            {predictionQuestion.length > 50 ? (
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            {predictionQuestion}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="hover-text">
                                                                        {predictionQuestion.substring(0, 50)}...
                                                                    </span>
                                                                </OverlayTrigger>
                                                            ) : (
                                                                predictionQuestion
                                                            )}
                                                        </td>
                                                        <td>
                                                            {predictionType}
                                                            {predictionDetail?.prediction_category !== "REGULAR" && (
                                                                <>
                                                                    {predictionDetail?.prediction_category === "POLL" && " ( POLL )"}
                                                                    {predictionDetail?.prediction_category === "QUIZ" && " ( QUIZ )"}
                                                                    <span className="capitalized">
                                                                        &nbsp; {predictionDetail?.solo_type}
                                                                    </span>

                                                                </>
                                                            )}
                                                        </td>
                                                        <td style={{ textTransform: 'capitalize' }}> {predictionDetail?.data_provider ? predictionDetail.data_provider : "N/A"}</td>
                                                        <td> {predictionDetail?.startDateTime ? moment(predictionDetail.startDateTime * 1000).format(dateFormate) : "N/A"}</td>
                                                        <td>{predictionDetail?.endDateTime ? moment(predictionDetail.endDateTime * 1000).format(dateFormate) : "N/A"}</td>
                                                        <td>{predictionDetail?.amount ? formatWithDecimals(predictionDetail?.amount, predictionDetail?.decimal) : "N/A"}</td>
                                                        <td>
                                                            {
                                                                <Dropdown>
                                                                    <Dropdown.Toggle
                                                                        variant={data.status == "FAILED" ? "danger" : "success"}
                                                                        id="dropdown-basic">
                                                                        {data.status == "FAILED" ? "FAILED" : "PENDING"}
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {
                                                                            statusDropdown.map((item) => (
                                                                                data.status !== item.id &&
                                                                                <Dropdown.Item className="item-on-dropdown"
                                                                                    onClick={() => handleStatusConfimation([data._id], data.status)}
                                                                                >
                                                                                    {item.name}
                                                                                </Dropdown.Item>
                                                                            ))
                                                                        }
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            }
                                                        </td>
                                                        {filterValue != "PENDING"  && showGasfee ? <>
                                                            <td>{data?.gasRequiredLaunched ? `${(Number(data.gasRequiredLaunched)/(10**18))} POL`:"-" }</td>
                                                            <td>{data?.failedReasonLaunched ? data.failedReasonLaunched :"-" }</td>
                                                        </>:""}
                                                    </tr>
                                                );
                                            })
                                        )
                                            : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </Table>

                                {totalItems && totalItems > 0 ? (
                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={totalItems}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                        prevPageText={"Prev"}
                                        nextPageText={"Next"}
                                    />
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>


            <Modal show={showSoloModal} onHide={() => setShowSoloModal(false)} centered className="message-content-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add Solo Prediction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="gemSelect">
                        <Form.Label>Select Solo Prediction type</Form.Label>
                        <Form.Select onChange={(e) => setSoloPredictionType(e.target.value)} value={soloPredictionType} aria-label="Select Gem Type">
                            <option value="pearls">Pearls</option>
                            <option value="sapphires">Sapphires</option>
                            <option value="rubies">Rubies</option>
                            <option value="emeralds">Emeralds</option>
                            <option value="diamonds">Diamonds</option>
                            <option value="pebbles">Pebbles</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => getSoloPredictionCount(soloPredictionType)}>Add Solo {soloPredictionType} Prediction</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showFolioGroupModal} onHide={() => setShowFolioGroupModal(false)} centered className="message-content-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add Folio Group Prediction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="gemSelect">
                        <Form.Label>Select A Day</Form.Label>
                        <Form.Select onChange={(e) => setFolioGroupPredictionType(e.target.value)} value={folioGroupPredictionType} aria-label="Select Gem Type">
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => getFolioGroupPredictionCount(folioGroupPredictionType)}>Add Folio Group {folioGroupPredictionType} Prediction</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showDailyGroupModal} onHide={() => setShowDailyGroupModal(false)} centered className="message-content-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add Daily Group Prediction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="gemSelect">
                        <Form.Label>Select A Day</Form.Label>
                        <Form.Select onChange={(e) => setDailyGroupPredictionType(e.target.value)} value={dailyGroupPredictionType} aria-label="Select Gem Type">
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => getDailyGroupPredictionCount(dailyGroupPredictionType)}>Add Daily Group {dailyGroupPredictionType} Prediction</Button>
                </Modal.Footer>
            </Modal>


        </div>
    );
};
